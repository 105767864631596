module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-3c2fd02889/3/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.13.1-2036e9f84e-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"名古屋シティフォレスター倶楽部","short_name":"NCFC","description":"あなたの近くに森はありますか？森が私達の活動場所です。名古屋シティフォレスター倶楽部では2000年の発足以来22年間メンバーと共に森林整備活動や森林・林業ボランティア活動を行っています。あなたもぜひ活動に加わり「緑を守り育てる」第一歩を踏み出しませんか？","start_url":"/","background_color":"#ffffff","lang":"ja","theme_color":"#ffffff","display":"standalone","icons":[{"src":"/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"},{"src":"/icon-192.png","sizes":"192x192","type":"image/png"},{"src":"/icon-512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-553abb5722/3/.yarn/berry/cache/gatsby-plugin-offline-npm-6.13.3-f9ce207914-10c0.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-cb7e87c7d8/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
